export default function useApplicationCRUD() {
  const { $portalApi } = useNuxtApp()
  const { t } = useI18n()

  const keyId = useId()

  // Create app
  const application = useState<ApplicationState>(`application-state-${keyId}`, () => ({
    createError: null,
    loader: false,
    newApp: undefined,
  }))

  const applicationForm = useState<ApplicationFormState>(`application-form-${keyId}`,() => ({
    name: '',
    description: '',
    authStrategy: '',
    clientId: '',
  }))

  const credentialType = useState<CREDENTIAL_TYPE | null>(`application-credential-type-${keyId}`, () => null)

  /**
   * Creates a new application
   * @returns {Promise<void>}
   */
  const createApplication = async (): Promise<void> => {
    if (!applicationForm.value.name.trim()) {
      application.value.createError = createError({ data:  { detail: t('errors.general.param_should_not_be_empty', { param: 'name' }) } })
      return
    }
    try {
      application.value.loader = true

      application.value.newApp = await $portalApi('/api/v3/applications', {
        method: 'POST',
        body: {
          name: applicationForm.value.name,
          description: applicationForm.value.description,
          auth_strategy_id: applicationForm.value.authStrategy,
          client_id: applicationForm.value.clientId,
        },
      })
    } catch (e: any) {
      application.value.createError = e
    } finally {
      application.value.loader = false
    }
  }

  const deleteApplicationError = useState<Record <string, any> | null>(`delete-application-error-${keyId}`,() => null)

  /**
   * Deletes application
   * @param {string} applicationId - Application ID to delete.
   * @returns {Promise<void>}
   */
  const deleteApplication = async (applicationId: string): Promise<void> => {
    try {
      deleteApplicationError.value = null

      if (applicationId) {
        await $portalApi('/api/v3/applications/{applicationId}', {
          path: {
            applicationId,
          },
          method: 'DELETE',
        })
      } else {
        deleteApplicationError.value = createError({ data:  { detail: t('errors.general.param_should_not_be_empty', { param: 'applicationId' }) } })
      }
    } catch (error: any) {
      deleteApplicationError.value = error
    }
  }

  // Get application details

  // Global state
  const appDetails = useState<PortalApiResponseTemp<'get-application'> | undefined>(() => undefined)
  const appDetailsError = useState<Record <string, any> | null>(`application-details-error-${keyId}`,() => null)

  /**
   * Get application details
   * @param {string} applicationId - Application ID to get details.
   * @returns {Promise<void>}
   */
  const getApplicationDetails = async (applicationId: string): Promise<void> => {
    try {
      if (applicationId) {
        const result: PortalApiResponseTemp<'get-application'> | undefined = await $portalApi('/api/v3/applications/{applicationId}', {
          path: {
            applicationId,
          },
        })

        appDetails.value = result
      } else {
        appDetailsError.value = createError({ data:  { detail: t('errors.general.param_should_not_be_empty', { param: 'applicationId' }) } })
      }
    } catch (e: any) {
      appDetailsError.value = e
    }
  }

  return {
    createApplication,
    application,
    applicationForm,
    deleteApplicationError,
    deleteApplication,
    getApplicationDetails,
    appDetails,
    appDetailsError,
    credentialType,
  }
}
